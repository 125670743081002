export default {
  state: {
    listOfPosts: [
      {
        id: 1,
        src: require("@/assets/img/latest1.png"),
        alt: "post image",
        title: "Best For Any Office & Business Interior Solution",
        firstparagraph:
          " Lorem ipsum dolor sit amet, adipiscing Aliquam eusem vitae turpis dignissim maximus.posuere in. Contrary to popular belief.",
        secondparagraph:
          "Lorem Ipsum is not simply random text. It has roots in a piece of classica.",
        date: "26 December, 2022",
      },
      {
        id: 2,
        src: require("@/assets/img/latest2.jpg"),
        alt: "post image",
        title: "Latest Luxurious Invented Interior Designing Ideas",
        firstparagraph:
          " Lorem ipsum dolor sit amet, adipiscing Aliquam eusem vitae turpis dignissim maximus.posuere in. Contrary to popular belief.",
        secondparagraph:
          "Lorem Ipsum is not simply random text. It has roots in a piece of classica.",
        date: "22 November, 2023",
      },
      {
        id: 3,
        src: require("@/assets/img/latest3.jpg"),
        alt: "post image",
        title: "Low Cost Latest Invented Interior Designing Ideas",
        firstparagraph:
          " Lorem ipsum dolor sit amet, adipiscing Aliquam eusem vitae turpis dignissim maximus.posuere in. Contrary to popular belief.",
        secondparagraph:
          "Lorem Ipsum is not simply random text. It has roots in a piece of classica.",
        date: "22 November, 2023",
      },
    ],
    products: [
      {
        id: 1,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December, 2022",
      },
      {
        id: "2",
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Living Design",
        title: "Low Cost Latest Invented Interior Designing Ideas.",
        date: "22 December, 2022",
      },
      {
        id: 3,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Interior Design",
        title: "Best For Any Office & Business Interior Solution",
        date: "25 December, 2022",
      },
      {
        id: 4,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December, 2022",
      },
      {
        id: 5,
        src: require("@/assets/img/content5.png"),
        alt: "news photo",
        tag: "Living Design",
        title: "Low Cost Latest Invented Interior Designing Ideas.",
        date: "22 December, 2022",
      },
      {
        id: 6,
        src: require("@/assets/img/content6.png"),
        alt: "news photo",
        tag: "Interior Design",
        title: "Best For Any Office & Business Interior Solution",
        date: "25 December, 2022",
      },
      {
        id: 7,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 8,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 9,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 10,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 11,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 12,
        src: require("@/assets/img/content1.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },

      {
        id: 13,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 14,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 15,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 16,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 17,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 18,
        src: require("@/assets/img/content2.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },

      {
        id: 19,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 20,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 21,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 22,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 23,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 24,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },

      {
        id: 25,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 26,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 27,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 28,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 29,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 30,
        src: require("@/assets/img/content4.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },

      {
        id: 31,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 32,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 33,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 34,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 35,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
      {
        id: 36,
        src: require("@/assets/img/content3.png"),
        alt: "news photo",
        tag: "Kitchan Design",
        title: "Let’s Get Solution For Building Construction Work",
        date: "26 December,2022",
      },
    ],
  },
  getters: {
    getListOfProducts(state) {
      return state.products;
    },
    getlistOfPosts(state) {
      return state.listOfPosts;
    },
  },
}