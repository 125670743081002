export default {
  state: {
    allCategoriesList: [
      {
        id: "1",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom1.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "2",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "3",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "4",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "5",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom5.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "6",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "7",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "8",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom8.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "9",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom6.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "10",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "11",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom9.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "12",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "13",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom8.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "14",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom1.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "15",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "16",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom9.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "17",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom1.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "18",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "19",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "20",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom5.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "21",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom4.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "22",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "23",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "24",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom8.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "25",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom3.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "26",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "27",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom1.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "28",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom7.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "29",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom8.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "30",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "31",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom4.png"),
        alt: "bedroom seven",
        firstparagraph: "System Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "32",
        tag: "bathroom",
        src: require("@/assets/img/project-bathRoom5.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "33",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom0.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bedroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "34",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom1.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "35",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom2.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "36",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom3.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "37",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom4.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "38",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom5.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "39",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom6.png"),
        alt: "bedroom seven",
        firstparagraph: "System Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "40",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom7.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "41",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom8.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bedroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "42",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom1.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "43",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom2.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "44",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "45",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom12.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "46",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom5.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "47",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom6.png"),
        alt: "bedroom seven",
        firstparagraph: "System Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "48",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom7.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "49",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom5.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bedroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "50",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom1.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "51",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom2.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "52",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom3.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "53",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom7.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "54",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom0.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "55",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom6.png"),
        alt: "bedroom seven",
        firstparagraph: "System Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "56",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom2.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "57",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom8.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Bedroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "58",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom1.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "59",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom0.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Bathroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "60",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "61",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom12.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "62",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom5.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "63",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom6.png"),
        alt: "bedroom seven",
        firstparagraph: "System Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "64",
        tag: "bedroom",
        src: require("@/assets/img/project-bedRoom3.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Bathroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "65",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen1.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "66",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "67",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "68",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "69",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen5.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "70",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "71",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "72",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen8.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "73",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen9.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "74",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "75",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "76",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen8.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "77",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen4.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "78",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "79",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "80",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen5.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "81",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen3.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "82",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "83",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen1.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "84",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen5.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "85",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen4.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "86",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "87",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "88",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen8.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "89",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen9.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "90",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen2.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "91",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen3.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "92",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen4.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "93",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen8.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "94",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen4.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "95",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Kitchen",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "96",
        tag: "kitchen",
        src: require("@/assets/img/project-kitchen7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Kitchen Table",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "97",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom1.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "98",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "99",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "100",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "101",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom5.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "102",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "103",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "104",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom8.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "105",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom6.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "106",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom4.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "107",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom3.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "108",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom2.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "109",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom7.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "110",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom1.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "111",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom8.png"),
        alt: "bedroom seven",
        firstparagraph: "System Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "112",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom5.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "113",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom1.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "114",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom2.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "115",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom12.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "116",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom4.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "117",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom15.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "118",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom6.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "119",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom7.png"),
        alt: "bedroom seven",
        firstparagraph: "System Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "120",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom14.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "121",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom3.png"),
        alt: "bedroom eight",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },

      {
        id: "122",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom8.png"),
        alt: "bedroom one",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "123",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom4.png"),
        alt: "bedroom two",
        firstparagraph: "Classic Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "124",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom6.png"),
        alt: "bedroom three",
        firstparagraph: "Minimal Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "125",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom2.png"),
        alt: "bedroom four",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "126",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom7.png"),
        alt: "bedroom five",
        firstparagraph: "Minimal Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "127",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom1.png"),
        alt: "bedroom six",
        firstparagraph: "Modern Livingroom",
        secondparagraph: "Decor / Artchitecture",
      },
      {
        id: "128",
        tag: "livingroom",
        src: require("@/assets/img/project-livingRoom5.png"),
        alt: "bedroom seven",
        firstparagraph: "System Livingroom Table",
        secondparagraph: "Decor / Artchitecture",
      },
    ],
  },
  getters: {
    getAllCategoriesList(state) {
      return state.allCategoriesList;
    },
  },
};
